import { FC, Dispatch, SetStateAction } from 'react';

import { Box, Input, FormControl, FormLabel } from '@mui/joy';

interface IFilters {
  amountFilterFrom: number;
  amountFilterTo: number;
  walletFilter: string;
  assetFilter: string;
  setAmountFilterFrom: Dispatch<SetStateAction<string>>;
  setAmountFilterTo: Dispatch<SetStateAction<string>>;
  setWalletFilter: Dispatch<SetStateAction<string>>;
  setAssetFilter: Dispatch<SetStateAction<string>>;
}

export const Filters: FC<IFilters> = ({
  amountFilterFrom,
  amountFilterTo,
  walletFilter,
  assetFilter,
  setAmountFilterFrom,
  setAmountFilterTo,
  setWalletFilter,
  setAssetFilter,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <form
        style={{
          display: 'flex',
          gap: 10,
        }}
      >
        <FormControl>
          <FormLabel>Filter by amount from - to</FormLabel>
          <div
            style={{
              display: 'flex',
              gap: 6,
            }}
          >
            <Input
              value={amountFilterFrom}
              onChange={(e) => setAmountFilterFrom(e.target.value)}
              type="number"
            />

            <Input
              value={amountFilterTo}
              onChange={(e) => setAmountFilterTo(e.target.value)}
              type="number"
            />
          </div>
        </FormControl>
        <FormControl>
          <FormLabel>Filter by wallet</FormLabel>
          <Input
            value={walletFilter}
            onChange={(e) => setWalletFilter(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Filter by Asset</FormLabel>
          <Input
            value={assetFilter}
            onChange={(e) => setAssetFilter(e.target.value)}
          />
        </FormControl>
      </form>
    </Box>
  );
};
