import { useEffect, useState } from 'react';
import Layout from '../features/components/Layout';
import {
  useGetManagersByAdminIdMutation,
  useGetAdminManagersMutation,
  usePostValidateJwtMutation,
} from '../app/services/admin';
import AdminManagersTable from '../features/components/ui/AdminManagersTable';

export default function AdminManagers() {
  const [postValidateJwt] = usePostValidateJwtMutation();
  const [getManagersByAdminId] = useGetManagersByAdminIdMutation();
  const [getAdminManagers] = useGetAdminManagersMutation();

  const [userRole, setUserRole] = useState(null);
  const [managersList, setManagersList] = useState(null);

  useEffect(() => {
    const validateJwt = async () => {
      const { UserRole } = await postValidateJwt().unwrap();
      if (UserRole) setUserRole(UserRole);
      return UserRole;
    };
    const fetchAdminModeratorsList = async () => {
      const managersList = await getAdminManagers().unwrap();
      if (managersList) setManagersList(managersList);
    };

    validateJwt().then((role) => {
      if (role === 'admin') fetchAdminModeratorsList();
    });
  }, [postValidateJwt, getManagersByAdminId, getAdminManagers]);

  return (
    <Layout user={{ role: userRole }}>
      <AdminManagersTable accounts={managersList} />
    </Layout>
  );
}
