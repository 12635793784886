import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../features/components/Layout';
import {
  useGetManagersByAdminIdMutation,
  useGetAdminManagersMutation,
  usePostValidateJwtMutation,
} from '../app/services/admin';
import ManagersTable from '../features/components/ui/ManagersTable';

export default function SuperadminManagers() {
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split('/').filter(Boolean);
  const lastSegment = segments[segments.length - 1];

  const [postValidateJwt] = usePostValidateJwtMutation();
  const [getManagersByAdminId] = useGetManagersByAdminIdMutation();
  const [getAdminManagers] = useGetAdminManagersMutation();

  const [userRole, setUserRole] = useState(null);
  const [managersList, setManagersList] = useState(null);

  useEffect(() => {
    if (!lastSegment || !/^\d+$/.test(lastSegment)) return;

    const validateJwt = async () => {
      const { UserRole } = await postValidateJwt().unwrap();
      if (UserRole) setUserRole(UserRole);
      return UserRole;
    };
    const fetchSuperadminModeratorsList = async () => {
      const managersList = await getManagersByAdminId(lastSegment).unwrap();
      if (managersList) setManagersList(managersList.Users);
    };
    const fetchAdminModeratorsList = async () => {
      const managersList = await getAdminManagers().unwrap();
      if (managersList) setManagersList(managersList.Users);
    };

    validateJwt().then((role) => {
      console.log('role', role);
      if (role === 'super-admin') fetchSuperadminModeratorsList();
      if (role === 'admin') fetchAdminModeratorsList();
    });
  }, [postValidateJwt, getManagersByAdminId, getAdminManagers, lastSegment]);

  return (
    <Layout user={{ role: userRole }}>
      <ManagersTable accounts={managersList} />
    </Layout>
  );
}
