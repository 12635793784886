import Layout from '../features/components/Layout';
import { useEffect, useState } from 'react';
import {
  useGetAdministratorsMutation,
  usePostValidateJwtMutation,
} from '../app/services/admin';
import AdministratorsTable from '../features/components/ui/AdministratorsTable';

export default function Administrators() {
  const [postValidateJwt] = usePostValidateJwtMutation();
  const [getAdministrators] = useGetAdministratorsMutation();

  const [userRole, setUserRole] = useState(null);
  const [administratorsList, setAdministratorsList] = useState(null);

  useEffect(() => {
    const validateJwt = async () => {
      const { UserRole } = await postValidateJwt().unwrap();
      if (UserRole) setUserRole(UserRole);
      return UserRole;
    };
    const fetchAdministratorsList = async () => {
      const adminsList = await getAdministrators().unwrap();
      if (adminsList) setAdministratorsList(adminsList.Users);
    };

    validateJwt().then((role) => {
      if (role === 'super-admin') fetchAdministratorsList();
    });
  }, [postValidateJwt, getAdministrators]);

  return (
    <Layout user={{ role: userRole }}>
      <AdministratorsTable accounts={administratorsList} />
    </Layout>
  );
}
