import {
  Box,
  Typography,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@mui/joy';
import copy from 'copy-to-clipboard';
import { Alert } from '../../features/components/Alert';
import Layout from '../../features/components/Layout';
import { useEffect, useState } from 'react';
import {
  useGetAvailableAllowanceMutation,
  usePostValidateJwtMutation,
  useGetReferralCodeMutation,
  useGetManagerWalletsMutation,
  useGetFirstWalletConnectDatesMutation,
} from '../../app/services/admin';

import { AllowanceData, formatWithDecimals } from '../../app/utils';
import AllowanceTable from '../../features/components/ui/AllowanceTable';
import { appDomain } from '../../config/consts';

import { MakeTransferModal } from '../../components/Modals/MakeTransferModal';
import { Filters } from './ui/components/Filters';
import { AllowancesActionButtons } from '../../components/AllowancesActionButtons/AllowancesActionButtons';
import WalletsTable from '../../features/components/ui/WalletsTable';

export default function Home() {
  const [getAllowanceData, { data: allowanceData, isLoading, isSuccess }] =
    useGetAvailableAllowanceMutation();

  const [
    getManagerWallets,
    {
      data: managerWalletsData,
      isLoading: isManagerWalletsLoading,
      isSuccess: isManagerWalletsSuccess,
    },
  ] = useGetManagerWalletsMutation();

  const [getFirstWalletConnectDates, { data: firstWalletConnectDates }] =
    useGetFirstWalletConnectDatesMutation();

  const [userRole, setUserRole] = useState<string | null>(null);
  const [referralCode, setReferralCode] = useState<string | null>(null);

  const [filteredData, setFilteredData] = useState<AllowanceData[]>([]);
  const [amountFilterFrom, setAmountFilterFrom] = useState<any>('');
  const [amountFilterTo, setAmountFilterTo] = useState<any>('');
  const [walletFilter, setWalletFilter] = useState<any>('');
  const [assetFilter, setAssetFilter] = useState<any>('');
  const [selectedWallets, setSelectedWallets] = useState<any>({});
  const [selectedAllowance, setSelectedAllowance] = useState<AllowanceData>();
  const [openModal, setOpenModal] = useState(false);
  const [loadingAllowances, setLoadingAllowances] = useState<AllowanceData[]>();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState<string | null>(null);
  const [alertErrorText, setAlertErrorText] = useState<string | null>(null);

  const [postValidateJwt] = usePostValidateJwtMutation();

  const [getReferralCode] = useGetReferralCodeMutation();

  useEffect(() => {
    const validateJwt = async () => {
      const { UserRole } = await postValidateJwt().unwrap();
      if (UserRole) setUserRole(UserRole);
      return UserRole;
    };
    const fetchReferralCode = async () => {
      const refCode = await getReferralCode().unwrap();
      if (refCode) setReferralCode(refCode);
    };

    validateJwt().then((role) => {
      if (role === 'manager') fetchReferralCode();
    });
  }, [postValidateJwt, getReferralCode]);

  useEffect(() => {
    if (userRole !== 'super-admin' && userRole !== 'admin') return;
    getAllowanceData(userRole);
  }, [getAllowanceData, userRole]);

  useEffect(() => {
    if (userRole !== 'super-admin' && userRole !== 'admin') return;
    getFirstWalletConnectDates(userRole);
  }, [getFirstWalletConnectDates, userRole]);

  useEffect(() => {
    if (userRole !== 'manager') return;
    getManagerWallets();
  }, [getManagerWallets, userRole]);

  useEffect(() => {
    if (isSuccess && allowanceData) {
      filterData();
    }
  }, [
    isSuccess,
    allowanceData,
    walletFilter,
    assetFilter,
    amountFilterFrom,
    amountFilterTo,
  ]);

  const filterData = () => {
    let filtered = allowanceData.filter((item: any) => {
      const amount = formatWithDecimals(
        item.AvailableAllowance.CurrentBalance,
        item.Asset.Decimal,
      );
      const asset = item.Asset.Name.toLowerCase();

      const wallet = item.AvailableAllowance.Address.toLowerCase();
      return (
        (!amountFilterFrom || amount >= parseInt(amountFilterFrom)) &&
        (!amountFilterTo || amount <= parseInt(amountFilterTo)) &&
        (!assetFilter || asset.includes(assetFilter.toLowerCase())) &&
        (!walletFilter || wallet.includes(walletFilter.toLowerCase()))
      );
    });
    setFilteredData(filtered);
  };

  const toggleWalletSelection = (allowance: AllowanceData) => {
    setSelectedWallets((prev: any) => ({
      ...prev,
      [allowance.AvailableAllowance.Address]:
        !prev[allowance.AvailableAllowance.Address],
    }));
  };

  const handleMakeTransfer = (allowance: AllowanceData) => {
    if (userRole !== 'super-admin' && userRole !== 'admin') return;
    setSelectedAllowance(allowance);
    setOpenModal(true);
  };

  return (
    <Layout user={{ role: userRole }}>
      {(userRole === 'super-admin' || userRole === 'admin') && (
        <>
          <MakeTransferModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            selectedAllowance={selectedAllowance ?? null}
            userRole={userRole}
          />
          <Filters
            amountFilterFrom={amountFilterFrom}
            amountFilterTo={amountFilterTo}
            walletFilter={walletFilter}
            assetFilter={assetFilter}
            setAmountFilterFrom={setAmountFilterFrom}
            setAmountFilterTo={setAmountFilterTo}
            setWalletFilter={setWalletFilter}
            setAssetFilter={setAssetFilter}
          />
          <AllowancesActionButtons
            filteredData={filteredData}
            selectedWallets={selectedWallets}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : isSuccess ? (
              <AllowanceTable
                allowances={filteredData}
                firstWalletConnectDates={firstWalletConnectDates}
                loadingAllowances={loadingAllowances}
                onMakeTransfer={handleMakeTransfer}
                onSelect={toggleWalletSelection}
                userRole={userRole}
              />
            ) : (
              <Typography color="danger">Error loading data</Typography>
            )}
          </Box>
        </>
      )}

      {userRole === 'manager' && (
        <>
          <WalletsTable walletsList={managerWalletsData} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 450,
            }}
          >
            <FormControl>
              <FormLabel>Referral code</FormLabel>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Input
                  value={`${appDomain}?ref-code=${referralCode?.toString()}`}
                  type="text"
                  fullWidth
                />
                <Button
                  onClick={() => {
                    if (referralCode)
                      copy(referralCode?.toString(), {
                        onCopy: () => {
                          setAlertSuccessText(
                            'Referral code is uccessfully copied!',
                          );
                          setOpenSuccessAlert(true);
                        },
                      });
                  }}
                  variant="outlined"
                >
                  Copy
                </Button>
              </Box>
            </FormControl>
          </Box>
        </>
      )}

      {/* Alerts */}
      {alertErrorText && (
        <Alert
          open={openErrorAlert}
          setOpen={setOpenErrorAlert}
          color="danger"
          duration={3000}
          text={alertErrorText}
        />
      )}
      {alertSuccessText && (
        <Alert
          open={openSuccessAlert}
          setOpen={setOpenSuccessAlert}
          color="success"
          duration={3000}
          text={alertSuccessText}
        />
      )}
    </Layout>
  );
}
