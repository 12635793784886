import { Box, Table, Sheet } from '@mui/joy';
import { UsersManagersData } from '../../../app/utils';

interface AdminManagersTableProps {
  accounts: UsersManagersData[] | null;
}

const AdminManagersTable: React.FC<AdminManagersTableProps> = ({
  accounts,
}) => {
  return accounts ? (
    <Sheet>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Login</th>
          </tr>
        </thead>
        <tbody>
          {accounts?.map((account) => (
            <tr key={account.Id || account.ID}>
              <td>{account.Id || account.ID}</td>
              <td>{account.Login}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  ) : (
    <Box>Haven't created managers yet.</Box>
  );
};

export default AdminManagersTable;
