import { useEffect, useState } from 'react';
import { Box, Input, Button, Checkbox, FormControl, FormLabel } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import FormGroup from '@mui/material/FormGroup';
import { Alert } from '../features/components/Alert';
import Layout from '../features/components/Layout';
import {
  useGetSettingsMutation,
  usePutSettingsMutation,
  usePostValidateJwtMutation,
} from '../app/services/admin';

interface ISettings {
  Key: number | string;
  Value: number | string;
}

export default function Settings() {
  const amountDecimals = 18;

  const [userRole, setUserRole] = useState<string | null>(null);
  const [settingsInfo, setSettingsInfo] = useState<ISettings[] | null>(null);

  const [isAutoWithdrawalCheckbox, setIsAutoWithdrawalCheckbox] =
    useState<boolean>(false);
  const [minimalWithdrawalValue, setMinimalWithdrawalValue] = useState<
    number | string
  >(0);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState<string | null>(null);
  const [alertErrorText, setAlertErrorText] = useState<string | null>(null);

  const [getSettings] = useGetSettingsMutation();

  const [postValidateJwt] = usePostValidateJwtMutation();

  const [
    putSettings,
    {
      data: putSettingsData,
      isLoading: putSettingsLoading,
      isSuccess: putSettingsSuccess,
      isError: putSettingsError,
      originalArgs: putSettingsArgs,
    },
  ] = usePutSettingsMutation();

  useEffect(() => {
    const validateJwt = async () => {
      const { UserRole } = await postValidateJwt().unwrap();
      if (UserRole) setUserRole(UserRole);
      return UserRole;
    };

    const fetchSettingsInfo = async () => {
      const settingsInfo = await getSettings().unwrap();
      if (settingsInfo) setSettingsInfo(settingsInfo);
    };

    validateJwt().then((role) => {
      if (role === 'super-admin') fetchSettingsInfo();
    });
  }, [postValidateJwt, getSettings]);

  useEffect(() => {
    if (putSettingsSuccess) {
      setAlertSuccessText('Settings is successfully saved!');
      setOpenSuccessAlert(true);
    }

    if (putSettingsError) {
      console.error(
        "putSettings error: Settings isn't saved for some reasons...",
      );
      if (!alertErrorText)
        setAlertErrorText("Manager isn't created for some reasons...");
      setOpenErrorAlert(true);
    }
  }, [putSettingsSuccess, putSettingsError, alertErrorText, alertSuccessText]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsAutoWithdrawalCheckbox(event.target.checked);
  };

  useEffect(() => {
    const minimalWithdrawal =
      settingsInfo?.find((setting) => setting.Key === 'min_withdrawal')
        ?.Value || 0;

    const isAutoWithdrawal =
      settingsInfo?.some(
        (setting) =>
          setting.Key === 'auto_withdrawal' && setting.Value === 'true',
      ) || false;

    setIsAutoWithdrawalCheckbox(isAutoWithdrawal);
    if (minimalWithdrawal)
      setMinimalWithdrawalValue(
        Number(minimalWithdrawal) / 10 ** amountDecimals,
      );
  }, [settingsInfo]);

  return (
    <Layout user={{ role: userRole }}>
      <Divider />
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '100%',
          }}
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formElements = new FormData(event.currentTarget);
            const data = Object.fromEntries(formElements.entries());

            data.auto_withdrawal = isAutoWithdrawalCheckbox.toString();

            await putSettings({
              Key: 'auto_withdrawal',
              Value: data.auto_withdrawal,
            })
              .unwrap()
              .catch((err) => {
                setAlertErrorText(err.data);
              });

            await putSettings({
              Key: 'min_withdrawal',
              Value: String(+data.min_withdrawal * 10 ** amountDecimals),
            })
              .unwrap()
              .catch((err) => {
                setAlertErrorText(err.data);
              });
          }}
        >
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'flex-start',
              marginTop: 2,
            }}
          >
            <FormControl
              sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1 }}
            >
              <FormLabel>Autowithdrawal</FormLabel>
              <Checkbox
                checked={isAutoWithdrawalCheckbox}
                name="auto_withdrawal"
                onChange={handleCheckboxChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Minimal withdrawal</FormLabel>
              <Input
                name="min_withdrawal"
                type="text"
                value={minimalWithdrawalValue}
                onChange={(e) => setMinimalWithdrawalValue(e.target.value)}
              />
            </FormControl>
          </FormGroup>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Button
              type="submit"
              variant="outlined"
              loading={putSettingsLoading}
              style={{
                width: '100%',
                maxWidth: '202px',
                marginTop: '10px',
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>

      {alertErrorText && (
        <Alert
          open={openErrorAlert}
          setOpen={setOpenErrorAlert}
          color="danger"
          duration={3000}
          text={alertErrorText}
        />
      )}
      {alertSuccessText && (
        <Alert
          open={openSuccessAlert}
          setOpen={setOpenSuccessAlert}
          color="success"
          duration={3000}
          text={alertSuccessText}
        />
      )}
    </Layout>
  );
}
