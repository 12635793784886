import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
});

export const customBaseQuery = async (
  args: any,
  api: any,
  extraOptions: any,
) => {
  const login = Cookies.get('login');
  const password = Cookies.get('password');
  const accessToken = Cookies.get('at');

  let adjustedArgs = typeof args === 'string' ? { url: args } : { ...args };

  // Добавляем заголовок Authorization, если accessToken доступен
  if (accessToken) {
    if (!adjustedArgs.headers) {
      adjustedArgs.headers = {};
    }
    adjustedArgs.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  // if (login && password) {
  //   if (adjustedArgs.method === 'GET' || !adjustedArgs.method) {
  //     adjustedArgs.params = {
  //       login,
  //       password,
  //       ...adjustedArgs.params,
  //     };
  //   } else {
  //     adjustedArgs.body = {
  //       Login: login,
  //       Password: password,
  //       ...adjustedArgs.body,
  //     };
  //   }
  // }

  return baseQueryWithAuth(adjustedArgs, api, extraOptions);
};

export const saveToCookies = (key: string, value: string) => {
  Cookies.set(key, value);
};

export const getFromCookies = (key: string) => {
  return Cookies.get(key);
};

export const removeUser = () => {
  Cookies.remove('login');
  Cookies.remove('password');
};

export interface AvailableAllowance {
  ID: number;
  Address: string;
  BalanceAtCreated: string;
  CurrentBalance: string;
  CurrentAllowance: string;
  NetworkId: number;
  AssetId: number;
  CreatedAt: number;
}

export interface Asset {
  ID: number;
  Name: string;
  Address: string;
  Decimal: number;
  NetworkId: number;
  Price: string;
}

export interface AllowanceData {
  AvailableAllowance: AvailableAllowance;
  Asset: Asset;
}

export interface FirstWalletConnectDate {
  Address: string;
  CreatedAt: string;
}

export interface UsersAdministratorsData {
  Id: number;
  Login: string;
}

export interface UsersManagersData {
  Id: number;
  ID: number;
  Login: string;
}

export interface UsersWalletData {
  Address: string;
  InvitedBy: number;
}

function formatNumber(num: number, decimals: number): string {
  // Convert the number to a string in exponential notation
  let expForm = num.toExponential(decimals);

  // Split the string into coefficient and exponent
  let parts = expForm.split('e');
  let coefficient = parseFloat(parts[0]);
  let exponent = parseInt(parts[1]);

  // Format the coefficient using toFixed
  let formattedCoefficient = coefficient.toFixed(decimals);

  // Reconstruct the number in scientific notation
  return formattedCoefficient + 'e' + (exponent >= 0 ? '+' : '') + exponent;
}

// Функция для форматирования числа с учетом десятичных знаков
export const formatWithDecimals = (value: string, decimals: number): number => {
  try {
    const number = BigInt(value);
    const divisor = BigInt(10 ** decimals);
    const integerPart = number / divisor;
    const fractionalPart = number % divisor;

    const res = `${integerPart}.${fractionalPart
      .toString()
      .padStart(decimals, '0')}`;

    const numbered = parseFloat(res);
    console.log(numbered, formatNumber(numbered, 5));
    return Number(formatNumber(numbered, 5));
  } catch (e) {
    return 0;
  }
};

export const convertToDecimal = (
  formattedValue: string,
  decimals: number,
): string => {
  const [integerPart, fractionalPart = ''] = formattedValue.split('.');
  const cleanIntegerPart = integerPart.replace(/,/g, '');
  const paddedFractionalPart = fractionalPart
    .padEnd(decimals, '0')
    .slice(0, decimals);
  const rawValue = (cleanIntegerPart + paddedFractionalPart).replace(/^0+/, '');

  return rawValue || '0';
};

// Функция для определения названия сети
export const getNetworkName = (networkId: number): string => {
  switch (networkId) {
    case 2:
      return 'Tron';
    case 1:
      return 'BSC';
    default:
      return 'Unknown';
  }
};
