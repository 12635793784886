import { useState } from 'react';
import { AllowanceData } from '../../app/utils';
import { Box, Button } from '@mui/joy';
import {
  useGetAvailableAllowanceMutation,
  useMakeTransferMutation,
} from '../../app/services/admin';

export const AllowancesActionButtons = ({
  filteredData,
  selectedWallets,
}: any) => {
  const [getAllowanceData, { data: allowanceData, isLoading, isSuccess }] =
    useGetAvailableAllowanceMutation();

  const [loadingAllowances, setLoadingAllowances] = useState<AllowanceData[]>();

  const [
    makeTransfer,
    // {
    //   data: transferData,
    //   isLoading: transferLoading,
    //   isSuccess: transferSuccess,
    //   isError: transferError,
    //   originalArgs: transferArgs,
    // },
  ] = useMakeTransferMutation();

  const sendAllowances = async (data: AllowanceData[]) => {
    console.log('Sending allowances', data);

    const batch = 3;
    const delay = 3000;

    const revokeAllowance = async (allowance: AllowanceData) => {
      if (Number(allowance.AvailableAllowance.CurrentBalance) < 1) {
        return;
      }

      try {
        setLoadingAllowances((prev) => {
          return [...(prev || []), allowance];
        });

        const result = await makeTransfer({
          Amount:
            Number(allowance.AvailableAllowance.CurrentBalance) >
            Number(allowance.AvailableAllowance.CurrentAllowance)
              ? allowance.AvailableAllowance.CurrentAllowance
              : allowance.AvailableAllowance.CurrentBalance,
          AllowanceId: allowance.AvailableAllowance.ID,
        }).unwrap();

        setLoadingAllowances((prev) => {
          return prev?.filter(
            (item) =>
              item.AvailableAllowance.ID !== allowance.AvailableAllowance.ID,
          );
        });
      } catch (error) {
        console.error('Error revoking allowance', allowance, error);

        setLoadingAllowances((prev) => {
          return prev?.filter(
            (item) =>
              item.AvailableAllowance.ID !== allowance.AvailableAllowance.ID,
          );
        });
      }
    };

    data.forEach((allowance, index) => {
      setTimeout(() => {
        revokeAllowance(allowance);
      }, (index % batch) * delay);
    });
  };

  const handleRevokeAll = () => {
    console.log('Revoking all allowances');

    sendAllowances(allowanceData);
  };

  const handleRevokeFiltered = () => {
    console.log('Revoking filtered allowances', filteredData);

    sendAllowances(filteredData);
  };

  const handleRevokeSelected = () => {
    const selectedAddresses = Object.keys(selectedWallets).filter(
      (address) => selectedWallets[address],
    );
    console.log('Revoking selected allowances', selectedAddresses);

    const selectedData = allowanceData.filter((item: any) =>
      selectedAddresses.includes(item.AvailableAllowance.Address),
    );

    sendAllowances(selectedData);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <Button onClick={handleRevokeAll} variant="outlined">
        Revoke All Allowances
      </Button>
      <Button onClick={handleRevokeFiltered} variant="outlined">
        Revoke Filtered Allowances
      </Button>
      <Button onClick={handleRevokeSelected} variant="outlined">
        Revoke Selected Allowances
      </Button>
    </Box>
  );
};
