import { useState, useEffect } from 'react';
import {
  usePostCreateAdminMutation,
  usePostCreateManagerMutation,
} from '../../app/services/admin';
import { ModalTemplate } from '../../features/components/ui/Modal';
import { Input, Button, FormControl, FormLabel } from '@mui/joy';
import Drawer from '@mui/joy/Drawer';
import MenuList from './ui/MenuList';
import { Alert } from '../../features/components/Alert';

export default function Sidebar({
  isOpenSidebar,
  setIsOpenSidebar,
  userRole,
}: {
  isOpenSidebar: boolean;
  setIsOpenSidebar: any;
  userRole?: string | null;
}) {
  const [openModalCreateUser, setOpenModalCreateUser] = useState(false);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState<string | null>(null);
  const [alertErrorText, setAlertErrorText] = useState<string | null>(null);

  const [
    postCreateAdmin,
    {
      data: createAdminData,
      isLoading: createAdminLoading,
      isSuccess: createAdminSuccess,
      isError: createAdminError,
      originalArgs: createAdminArgs,
    },
  ] = usePostCreateAdminMutation();

  const [
    postCreateManager,
    {
      data: createManagerData,
      isLoading: createManagerLoading,
      isSuccess: createManagerSuccess,
      isError: createManagerError,
      originalArgs: createManagerArgs,
    },
  ] = usePostCreateManagerMutation();

  useEffect(() => {
    // create admin
    if (createAdminSuccess) {
      setAlertSuccessText('Admin is successfully created!');
      setOpenSuccessAlert(true);
      setOpenModalCreateUser(false);
    }

    if (createAdminError) {
      console.error(
        "postCreateAdmin error: Admin isn't created for some reasons...",
      );
      if (!alertErrorText)
        setAlertErrorText("Admin isn't created for soma reasons...");
      setOpenErrorAlert(true);
    }

    //create manager
    if (createManagerSuccess) {
      setAlertSuccessText('Manager is successfully created!');
      setOpenSuccessAlert(true);
      setOpenModalCreateUser(false);
    }

    if (createManagerError) {
      console.error(
        "postCreateManager error: Manager isn't created for some reasons...",
      );
      if (!alertErrorText)
        setAlertErrorText("Manager isn't created for soma reasons...");
      setOpenErrorAlert(true);
    }
  }, [
    alertErrorText,
    createAdminSuccess,
    createAdminError,
    createManagerSuccess,
    createManagerError,
  ]);

  const handleCreateUser = () => {
    setOpenModalCreateUser(true);
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={isOpenSidebar}
        onClose={() => setIsOpenSidebar(false)}
      >
        {<MenuList userRole={userRole} handleCreateUser={handleCreateUser} />}
      </Drawer>

      <ModalTemplate
        open={openModalCreateUser}
        setOpen={setOpenModalCreateUser}
        title={`Enter creditionals for new ${
          userRole === 'super-admin' ? 'admin' : 'manager'
        }`}
      >
        <form
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formElements = new FormData(event.currentTarget);
            const data = Object.fromEntries(formElements.entries());

            if (!data?.Login || !data?.Password) return;

            if (userRole === 'super-admin') {
              await postCreateAdmin({
                Login: data?.Login,
                Password: data?.Password,
              })
                .unwrap()
                .catch((err) => {
                  setAlertErrorText(err.data);
                });
            }

            if (userRole === 'admin') {
              await postCreateManager({
                Login: data?.Login,
                Password: data?.Password,
              })
                .unwrap()
                .catch((err) => {
                  setAlertErrorText(err.data);
                });
            }

            return;
          }}
        >
          <FormControl>
            <FormLabel>Login</FormLabel>
            <Input name="Login" type="text" required />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input name="Password" type="password" required />
          </FormControl>

          <Button
            type="submit"
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            loading={
              userRole === 'super-admin'
                ? createAdminLoading
                : createManagerLoading
            }
          >
            Create
          </Button>
        </form>
      </ModalTemplate>

      {alertErrorText && (
        <Alert
          open={openErrorAlert}
          setOpen={setOpenErrorAlert}
          color="danger"
          duration={3000}
          text={alertErrorText}
        />
      )}
      {alertSuccessText && (
        <Alert
          open={openSuccessAlert}
          setOpen={setOpenSuccessAlert}
          color="success"
          duration={3000}
          text={alertSuccessText}
        />
      )}
    </>
  );
}
