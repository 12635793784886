import { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button, FormControl, FormLabel } from '@mui/joy';
import { ModalTemplate } from '../../features/components/ui/Modal';
import {
  AllowanceData,
  convertToDecimal,
  formatWithDecimals,
} from '../../app/utils';
import { useMakeTransferMutation } from '../../app/services/admin';
import { setNotify } from '../../features/notifications/notifySlice';

interface IMakeTransferModal {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  selectedAllowance: AllowanceData | null;
  userRole: string | null;
}

export const MakeTransferModal: FC<IMakeTransferModal> = ({
  openModal,
  setOpenModal,
  selectedAllowance,
  userRole,
}) => {
  const dispatch = useDispatch();

  const [
    makeTransfer,
    {
      data: transferData,
      isLoading: transferLoading,
      isSuccess: transferSuccess,
      isError: transferError,
      originalArgs: transferArgs,
    },
  ] = useMakeTransferMutation();

  useEffect(() => {
    if (transferSuccess) {
      dispatch(
        setNotify({
          message: 'Transfer sended!',
          type: 'success',
          duration: 2000,
        }),
      );

      setOpenModal(false);
    }

    if (transferError) {
      dispatch(
        setNotify({
          message: 'Transfer error!',
          type: 'danger',
          duration: 2000,
        }),
      );
    }
  }, [transferSuccess, transferError]);

  return (
    <ModalTemplate
      open={openModal}
      setOpen={setOpenModal}
      title="Enter amount for transfer"
    >
      {selectedAllowance && (
        <form
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formElements = new FormData(event.currentTarget);
            const data = Object.fromEntries(formElements.entries());

            if (!selectedAllowance) {
              return;
            }

            if (
              Number(data?.Amount) >
              formatWithDecimals(
                selectedAllowance.AvailableAllowance.CurrentAllowance,
                selectedAllowance.Asset.Decimal,
              )
            ) {
              dispatch(
                setNotify({
                  message: 'amount > current allowance',
                  type: 'danger',
                  duration: 2000,
                }),
              );

              return;
            }

            if (
              Number(data?.Amount) >
              formatWithDecimals(
                selectedAllowance.AvailableAllowance.CurrentBalance,
                selectedAllowance.Asset.Decimal,
              )
            ) {
              dispatch(
                setNotify({
                  message: 'amount > balance',
                  type: 'danger',
                  duration: 2000,
                }),
              );

              return;
            }

            await makeTransfer({
              Amount: convertToDecimal(
                String(data?.Amount),
                selectedAllowance.Asset.Decimal,
              ),
              AllowanceId: selectedAllowance.AvailableAllowance.ID,
              userRole,
            });
          }}
        >
          <FormControl>
            <FormLabel>Amount</FormLabel>
            <Input
              name="Amount"
              type="number"
              required
              slotProps={{ input: { min: 0, step: 'any' } }}
              onFocus={(e) =>
                e.target.addEventListener(
                  'wheel',
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false },
                )
              }
              defaultValue={
                Number(selectedAllowance?.AvailableAllowance.CurrentBalance) >
                Number(selectedAllowance?.AvailableAllowance.CurrentAllowance)
                  ? formatWithDecimals(
                      selectedAllowance.AvailableAllowance.CurrentAllowance,
                      selectedAllowance.Asset.Decimal,
                    )
                  : formatWithDecimals(
                      selectedAllowance.AvailableAllowance.CurrentBalance,
                      selectedAllowance.Asset.Decimal,
                    )
              }
            />
          </FormControl>

          <Button
            type="submit"
            style={{
              width: '100%',
              marginTop: '10px',
            }}
            loading={transferLoading}
          >
            Make transfer
          </Button>
        </form>
      )}
    </ModalTemplate>
  );
};
