import React, { useState, useEffect } from 'react';
import { ModalTemplate } from './Modal';
import { Box, Table, Sheet, Button } from '@mui/joy';
import { UsersManagersData, UsersWalletData } from '../../../app/utils';
import { useGetInvitedWalletsByManagerIdMutation } from '../../../app/services/admin';

interface ManagersTableProps {
  accounts: UsersManagersData[] | null;
}

const ManagersTable: React.FC<ManagersTableProps> = ({ accounts }) => {
  const [getInvitedWalletsByManagerId] =
    useGetInvitedWalletsByManagerIdMutation();

  const [openModalInvitedWallets, setOpenModalInvitedWallets] =
    useState<boolean>(false);
  const [selectedManager, setSelecteManager] =
    useState<UsersManagersData | null>(null);
  const [walletsList, setWalletsList] = useState<UsersWalletData[] | null>(
    null,
  );

  const showInvitedWallets = (account: UsersManagersData) => {
    setOpenModalInvitedWallets(true);
    setSelecteManager(account);
  };

  useEffect(() => {
    if (!selectedManager) return;
    const fetchWalletsList = async () => {
      const walletsList = await getInvitedWalletsByManagerId(
        selectedManager.Id,
      ).unwrap();
      if (walletsList) setWalletsList(walletsList);
    };

    fetchWalletsList();
  }, [selectedManager, getInvitedWalletsByManagerId]);

  return accounts ? (
    <>
      <Sheet>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Login</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account) => (
              <tr key={account.Id || account.ID}>
                <td>{account.Id || account.ID}</td>
                <td>{account.Login}</td>
                <td>
                  <Button onClick={() => showInvitedWallets(account)}>
                    Show invited wallets
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>

      <ModalTemplate
        open={openModalInvitedWallets}
        setOpen={setOpenModalInvitedWallets}
        title={`Invited wallets list for ${selectedManager?.Login}`}
      >
        {walletsList ? (
          <Sheet>
            <Table>
              <thead>
                <tr>
                  <th>InvitedBy</th>
                  <th>Address</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {walletsList?.map((wallet) => (
                  <tr>
                    <td>{`${
                      accounts.find(
                        (manager) => manager.Id === wallet.InvitedBy,
                      )?.Login
                    } (id: ${wallet.InvitedBy})`}</td>
                    <td>{wallet.Address}</td>
                    {/* <td>
                      <Button onClick={() => showManagers(account.Id)}>
                        Show managers
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        ) : (
          <Box>Haven't invited yet.</Box>
        )}
      </ModalTemplate>
    </>
  ) : (
    <Box>Haven't created managers yet.</Box>
  );
};

export default ManagersTable;
