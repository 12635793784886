import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Divider from '@mui/joy/Divider';
import { menuList } from '../../../config/consts';
import { removeUser } from '../../../app/utils';

// interface IMuenuList {
//   label: string;
//   link: string;
// }

const handleLogout = () => {
  removeUser();
  window.location.reload();
};

export default function MenuList({
  userRole,
  handleCreateUser,
}: {
  userRole?: string | null;
  handleCreateUser: () => void;
}) {
  const menuByUserRole = userRole
    ? menuList[userRole.toString() as keyof typeof menuList]
    : menuList['manager'];

  return (
    <Box
      role="sidebar-menu"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuByUserRole
          .slice(0, menuByUserRole.length - 1)
          .map(({ label, link }) => (
            <ListItem key={label}>
              {label === 'Create User' &&
              (userRole === 'super-admin' || userRole === 'admin') ? (
                <ListItemButton onClick={handleCreateUser}>
                  Create {userRole === 'super-admin' ? 'admin' : 'manager'}
                </ListItemButton>
              ) : (
                <ListItemButton to={link} component={RouterLink}>
                  {label}
                </ListItemButton>
              )}
            </ListItem>
          ))}
      </List>
      {menuByUserRole.length > 1 && <Divider />}
      <List>
        {menuByUserRole.slice(-1).map(({ label }) => (
          <ListItem key={label}>
            {label === 'Logout' ? (
              <ListItemButton onClick={handleLogout}>{label}</ListItemButton>
            ) : (
              <ListItemButton>{label}</ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
