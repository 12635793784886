import React from 'react';
import dayjs from 'dayjs';
import { Table, Sheet, Button } from '@mui/joy';
import {
  AllowanceData,
  FirstWalletConnectDate,
  formatWithDecimals,
  getNetworkName,
} from '../../../app/utils';

interface AllowanceTableProps {
  allowances: AllowanceData[];
  firstWalletConnectDates: FirstWalletConnectDate[];
  loadingAllowances?: AllowanceData[];
  userRole?: string | null;
  onMakeTransfer: (allowance: AllowanceData) => void;
  onSelect: (allowance: AllowanceData) => void;
}

const AllowanceTable: React.FC<AllowanceTableProps> = ({
  allowances,
  firstWalletConnectDates,
  loadingAllowances,
  userRole,
  onMakeTransfer,
  onSelect,
}) => {
  const formatedAllowances = allowances?.map((allowance) => ({
    ...allowance,
    AvailableAllowance: {
      ...allowance.AvailableAllowance,
      dateFirstConnection: firstWalletConnectDates?.find(
        (wallet) => wallet.Address === allowance.AvailableAllowance.Address,
      )?.CreatedAt,
    },
  }));

  return (
    <Sheet>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '50px' }}></th>
            <th style={{ width: '50px' }}>ID</th>
            <th style={{ width: '150px' }}>Balance at Creation</th>
            <th style={{ width: '150px' }}>Current Allowance</th>
            <th style={{ width: '80px' }}>Asset</th>
            <th style={{ width: '400px' }}>Address</th>
            <th style={{ width: '150px' }}>Current Balance</th>
            <th style={{ width: '100px' }}>Network</th>
            <th style={{ width: '150px' }}>Date of added</th>
            <th style={{ width: '180px' }}>Date of first connection</th>
            {(userRole === 'super-admin' || userRole === 'admin') && (
              <th style={{ width: '100%', textAlign: 'right' }}>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {formatedAllowances?.map((allowance) => (
            <tr
              key={allowance.AvailableAllowance.ID}
              style={{
                opacity: loadingAllowances?.includes(allowance) ? 0.5 : 1,
              }}
            >
              <td>
                <input type="checkbox" onChange={() => onSelect(allowance)} />
              </td>
              <td>{allowance.AvailableAllowance.ID}</td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.BalanceAtCreated,
                  allowance.Asset.Decimal,
                )}{' '}
                {allowance.Asset.Name}
              </td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.CurrentAllowance,
                  allowance.Asset.Decimal,
                )}{' '}
                {allowance.Asset.Name}
              </td>
              <td>{allowance.Asset.Name}</td>
              <td
                style={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                }}
              >
                {allowance.AvailableAllowance.Address}
              </td>
              <td>
                {formatWithDecimals(
                  allowance.AvailableAllowance.CurrentBalance,
                  allowance.Asset.Decimal,
                )}{' '}
                {allowance.Asset.Name}
              </td>
              <td>{getNetworkName(allowance.AvailableAllowance.NetworkId)}</td>
              <td>
                {dayjs(allowance.AvailableAllowance.CreatedAt).format(
                  'YYYY-MM-DD',
                )}
              </td>
              <td>
                {allowance.AvailableAllowance.dateFirstConnection
                  ? dayjs(
                      allowance.AvailableAllowance.dateFirstConnection,
                    ).format('YYYY-MM-DD')
                  : '-'}
              </td>
              {(userRole === 'super-admin' || userRole === 'admin') && (
                <td style={{ textAlign: 'right' }}>
                  <Button onClick={() => onMakeTransfer(allowance)}>
                    Make transfer
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
};

export default AllowanceTable;
