export const appDomain = process.env.REACT_APP_URL;
export const menuList = {
  'super-admin': [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Administrators',
      link: '/administrators-list',
    },
    {
      label: 'Create User',
      link: '#',
    },
    {
      label: 'Settings',
      link: '/settings',
    },
    {
      label: 'Logout',
      link: '#',
    },
  ],
  admin: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Managers',
      link: '/managers-list',
    },
    {
      label: 'Create User',
      link: '#',
    },
    {
      label: 'Logout',
      link: '#',
    },
  ],
  manager: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Logout',
      link: '#',
    },
  ],
};
