import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Table, Sheet, Button } from '@mui/joy';
import ListItemButton from '@mui/joy/ListItemButton';
import { UsersAdministratorsData } from '../../../app/utils';

interface AdministratorsTableProps {
  accounts: UsersAdministratorsData[] | null;
}

const AdministratorsTable: React.FC<AdministratorsTableProps> = ({
  accounts,
}) => {
  return accounts ? (
    <Sheet>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Login</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {accounts?.map((account) => (
            <tr key={account.Id}>
              <td>{account.Id}</td>
              <td>{account.Login}</td>
              <td>
                <Button>
                  <ListItemButton
                    to={account.Id.toString()}
                    component={RouterLink}
                    sx={{
                      backgroundColor: 'transparent',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'transparent!important',
                        color: 'white!important',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'transparent!important',
                        color: 'white',
                      },
                    }}
                  >
                    Show managers
                  </ListItemButton>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  ) : (
    <Box>Haven't created administrators yet.</Box>
  );
};

export default AdministratorsTable;
