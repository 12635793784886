import { Box, Table, Sheet } from '@mui/joy';
import { UsersWalletData } from '../../../app/utils';

interface ManagersTableProps {
  walletsList: UsersWalletData[] | null;
}

const WalletsTable: React.FC<ManagersTableProps> = ({ walletsList }) => {
  return walletsList ? (
    <Sheet>
      <Table>
        <thead>
          <tr>
            <th>Invited By</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {walletsList?.map((walletRow, i) => (
            <tr key={i}>
              <td>{walletRow.InvitedBy}</td>
              <td>{walletRow.Address}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  ) : (
    <Box>No invitations yet.</Box>
  );
};

export default WalletsTable;
