import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';

export const admin = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'admin',
  endpoints: (builder) => ({
    getReferralCode: builder.mutation<any, void>({
      query: () => ({
        url: '/manager/referral-code',
        method: 'GET',
      }),
    }),
    getSettings: builder.mutation<any, void>({
      query: () => ({
        url: '/super-admin/settings',
        method: 'GET',
      }),
    }),
    getAdministrators: builder.mutation<any, void>({
      query: () => ({
        url: '/super-admin/admins',
        method: 'GET',
      }),
    }),
    getManagersByAdminId: builder.mutation<any, any>({
      query: (adminId) => ({
        url: `/super-admin/managers-by-admin/${adminId}`,
        method: 'GET',
      }),
    }),
    getAdminManagers: builder.mutation<any, void>({
      query: () => ({
        url: '/admin/managers',
        method: 'GET',
      }),
    }),
    getInvitedWalletsByManagerId: builder.mutation<any, any>({
      query: (managerId) => ({
        url: `/super-admin/wallets-by-manager/${managerId}`,
        method: 'GET',
      }),
    }),
    getManagerWallets: builder.mutation<any, void>({
      query: () => ({
        url: '/manager/wallets',
        method: 'GET',
      }),
    }),
    getFirstWalletConnectDates: builder.mutation<any, any>({
      query: (role) => ({
        url: `/${role}/date-of-first-wallet-connect`,
        method: 'GET',
      }),
    }),
    putSettings: builder.mutation<any, any>({
      query: (data: any) => ({
        url: '/super-admin/change-setting',
        method: 'PUT',
        body: data,
      }),
    }),
    getAvailableAllowance: builder.mutation<any, any>({
      query: (role) => ({
        url: `/${role}/available-allowance`,
        method: 'GET',
      }),
    }),
    postValidateJwt: builder.mutation<any, void>({
      query: () => ({
        url: '/validate-jwt',
        method: 'POST',
      }),
    }),
    postAddReceivingAddress: builder.mutation<any, any>({
      query: (data) => ({
        url: '/super-admin/add-receiving-address',
        method: 'POST',
        body: data,
      }),
    }),
    postCreateAdmin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: '/super-admin/create-admin',
        method: 'POST',
        body: data,
      }),
    }),
    postCreateManager: builder.mutation<any, any>({
      query: (data: any) => ({
        url: '/admin/create-manager',
        method: 'POST',
        body: data,
      }),
    }),
    makeTransfer: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `/${data.userRole}/execute-transfer`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSettingsMutation,
  useGetAdministratorsMutation,
  useGetManagersByAdminIdMutation,
  useGetAdminManagersMutation,
  useGetInvitedWalletsByManagerIdMutation,
  useGetManagerWalletsMutation,
  useGetFirstWalletConnectDatesMutation,
  usePostAddReceivingAddressMutation,
  usePutSettingsMutation,
  useGetAvailableAllowanceMutation,
  useMakeTransferMutation,
  usePostCreateAdminMutation,
  usePostCreateManagerMutation,
  useGetReferralCodeMutation,
  usePostValidateJwtMutation,
} = admin;

export const {
  getSettings,
  getAdministrators,
  getManagersByAdminId,
  getAdminManagers,
  getInvitedWalletsByManagerId,
  getManagerWallets,
  getFirstWalletConnectDates,
  postAddReceivingAddress,
  putSettings,
  makeTransfer,
  postCreateAdmin,
  postCreateManager,
  getReferralCode,
  postValidateJwt,
} = admin.endpoints;
