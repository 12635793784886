import { Route, Routes } from 'react-router-dom';
import Login from './features/auth/Login';
import { NotFound } from './pages/NotFound';
import Settings from './pages/Settings';
import Home from './pages/home/Home';
import Administrators from './pages/Administrators';
import SuperadminManagers from './pages/SuperadminManagers';
import AdminManagers from './pages/AdminManagers';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} index />
        <Route path="/settings" element={<Settings />} index />
        <Route path="/administrators-list" element={<Administrators />} index />
        <Route path="/managers-list" element={<AdminManagers />} index />
        <Route
          path="/administrators-list/*"
          element={<SuperadminManagers />}
          index
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
